export const organisationRoute = "/orgs";
export const eCommerceRoute = "/ecommerce";
export const profileRoute = "/profile";
export const resetPasswordRoute = "/resetpassword";
export const logoutRoute = "/logout";
export const technologiesRoute = "/tech/browse";
export const techDetailRoute = "/tech/browse/detail";
export const userListRoute = "/lists";
export const organisationNewRoute = "/organisation";
export const subscriptionRoute = "/subscription";
export const universityRoute = "/university";

export const noOfFilterToShow = 5000;
export const noOfRecordPerPage = 25;
export const debounceTime = 100;
export const noOfEcommerceItemList = 125;
export const noOfOrganisationItemList = 125;
export const ecommConstant = "ecomm-query";
export const orgConstant = "org-query";
export const deleteConstant = "delete";
export const flushConstant = "flush";
export const uploadConstant = "upload";
export const ecommLabelConstant = "ecomm";
export const orgLabelConstant = "org";
export const operationConstant = "operation";
export const installConstant = "install";
export const listConstant = "list";
export const fieldConstant = "field";
export const techConstant = "tech";
export const textConstant = "text";
export const valueConstant = "value";
export const dropdownConstant = "dropdown";
export const operationLabelConstant = "Operation";
export const selectValueLabelConstant = "Select value";
export const totalNumberOfRecordsAdd = 10;
export const monthlyConstant = "month";
export const yearlyConstant = "year";
export const optionalConstant = "opt";
export const textDebounceTime = 2000;
export const ALL_OPTION = "all";
export const TOP_10_OPTION = "top10";
export const TOP_20_OPTION = "top20";
export const BAR_OPTION = "bar";
export const PIE_OPTION = "pie";
export const startupVideoSrc =
  "https://player.vimeo.com/video/253989945?h=c6db007fe5&color=ef0800&title=0&byline=0&portrait=0";

import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { UniversityVideosData } from "./UniversityVideosData";
import { VideoCardContainer } from "./Style";
import { VideoType } from "./Type";
import VideoModal from "./VideoModal";

const University = () => {
  const [openVideoModal, setOpenVideoModal] = useState<VideoType | null>(null);

  return (
    <Grid container padding={"30px"}>
      {UniversityVideosData.section.map((item) => (
        <Grid key={item.sectionId} item xs={12} marginBottom={"30px"}>
          <Typography variant={"h6"} marginBottom={"10px"}>
            {item.title}
          </Typography>
          <Grid container spacing={"20px"}>
            {item.videos.map((video) => (
              <VideoCardContainer key={video.id} item xs={12} sm={4} md={3}>
                <img
                  onClick={() => setOpenVideoModal(video)}
                  src={video.thumbnail}
                  style={{ width: "100%", borderRadius: "10px" }}
                />
                <Typography
                  textAlign={"center"}
                  variant={"body1"}
                  marginBottom={"10px"}
                >
                  {video.title}
                </Typography>
              </VideoCardContainer>
            ))}
          </Grid>
        </Grid>
      ))}
      {openVideoModal && (
        <VideoModal
          selectedUniversityData={openVideoModal}
          handleClose={() => setOpenVideoModal(null)}
        />
      )}
    </Grid>
  );
};

export default University;

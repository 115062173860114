import { UniversityType } from "./Type";

export const UniversityVideosData: UniversityType = {
  section: [
    {
      sectionId: 1,
      title: "How to use",
      videos: [
        {
          id: 11,
          title: "Intro to ecommerce",
          src: "https://player.vimeo.com/video/253989945",
          thumbnail: "https://vumbnail.com/253989945.jpg",
        },
        {
          id: 22,
          title: "Advanced ecomm usecases",
          src: "https://player.vimeo.com/video/253989945",
          thumbnail: "https://vumbnail.com/253989945.jpg",
        },
      ],
    },
    {
      sectionId: 2,
      title: "Using export functionality",
      videos: [
        {
          id: 33,
          title: "Intro to ecommerce",
          src: "https://player.vimeo.com/video/253989945",
          thumbnail: "https://vumbnail.com/253989945.jpg",
        },
        {
          id: 44,
          title: "Advanced ecomm usecases",
          src: "https://player.vimeo.com/video/253989945",
          thumbnail: "https://vumbnail.com/253989945.jpg",
        },
      ],
    },
  ],
};

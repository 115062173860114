import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { VideoType } from "./Type";

type VideoModalProps = {
  handleClose: () => void;
  selectedUniversityData: VideoType;
};
const VideoModal: FC<VideoModalProps> = ({
  handleClose,
  selectedUniversityData,
}) => {
  return (
    <Dialog fullWidth maxWidth={"sm"} open onClose={handleClose}>
      <Grid position={"absolute"} container justifyContent={"flex-end"}>
        <AiOutlineClose
          onClick={handleClose}
          style={{ fontSize: 25, cursor: "pointer", margin: 20 }}
        />
      </Grid>
      <DialogContent style={{ paddingBottom: 0 }}>
        <Typography variant="h6" textAlign={"center"}>
          {selectedUniversityData.title}
        </Typography>
        <div>
          <iframe
            src={selectedUniversityData.src}
            width={"100%"}
            height={"400"}
            frameBorder={"0"}
            allowFullScreen
          ></iframe>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default VideoModal;

import Styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const VideoCardContainer = Styled(Grid)`
    cursor: pointer;
    transition: all .5s ease-in-out;
    &:hover {
        transform: scale(1.15);
    }
`;

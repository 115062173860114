export const env = process.env.REACT_APP_TYPE;
export const PORT = process.env.REACT_APP_PORT;

export const HOST = process.env.REACT_APP_HOST;

export const SITE_TITLE = process.env.REACT_APP_SITE_TITLE;

export const BASE_URL = process.env.REACT_APP_API_URL;

export const ANALYTICS_TOKEN = process.env.REACT_APP_ANALYTICS_TOKEN;

export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

export const AUTH0_FAILED_CODE = process.env.REACT_APP_AUTH0_FAILED_CODE;
export const URL_ENCODING_TYPE = process.env.REACT_APP_URL_ENCODING_TYPE;
export const FEEDBACK_TOKEN = process.env.REACT_APP_FEEDBACK_FISH_TOKEN;
export const SENTRY_CLIENT_ID = process.env.REACT_APP_SENTRY_CLIENT_ID;
export const ORGANISATION_FLAG = process.env.REACT_APP_ORGANISATION_FLAG;
export const ORGANISATION_NEW_FLAG =
  process.env.REACT_APP_NEW_ORGANISATION_FLAG;
export const ECOMMERCE_FLAG = process.env.REACT_APP_ECOMMERCE_FLAG;
export const TECHNOLOGY_FLAG = process.env.REACT_APP_TECHNOLOGY_FLAG;
export const USER_FACET_FLAG = process.env.REACT_APP_USER_FACET_FLAG;
export const SUBSCRIPTION_FLAG = process.env.REACT_APP_SUBSCRIPTION_FLAG;
export const UNIVERSITY_FLAG = process.env.REACT_APP_UNIVERSITY_FLAG;
export const WEBSITE_TOUR = process.env.REACT_APP_WEBSITE_TOUR;
export const SHOW_GRAPH_FLAG = process.env.REACT_APP_SHOW_GRAPH_FLAG;
export const SHOW_FAVOURITE_BUTTON_FLAG =
  process.env.REACT_APP_SHOW_FAVOURITE_BUTTON_FLAG;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { AiOutlineClose } from "react-icons/ai";
import ReactMarkdown from "react-markdown";
import { startupVideoSrc } from "../../constants/SiteConstants";
import { startupMarkupText } from "../../constants/markdownText";

type StartupModalProps = {
  handleClose: () => void;
};
const StartupModal: FC<StartupModalProps> = ({ handleClose }) => (
  <Dialog fullWidth maxWidth={"sm"} open onClose={handleClose}>
    <Grid position={"absolute"} container justifyContent={"flex-end"}>
      <AiOutlineClose
        onClick={handleClose}
        style={{ fontSize: 25, cursor: "pointer", margin: 20 }}
      />
    </Grid>
    <DialogContent>
      <Typography>
        <ReactMarkdown>{startupMarkupText}</ReactMarkdown>
      </Typography>
      <iframe
        src={startupVideoSrc}
        width={"100%"}
        height={"400"}
        frameBorder={"0"}
        allowFullScreen
      ></iframe>
    </DialogContent>
    <DialogActions style={{ paddingBottom: 0 }}>
      <Button onClick={handleClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

export default StartupModal;

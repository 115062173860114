import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { useBooleanState } from "../hooks/common/hooks";
import { navigationLinks } from "../../constants/navigationLinks";
import { useHistory } from "react-router";
import { HtmlToolTipWrapper, navigationMenuStyles } from "./SideNavbarStyle";
import {
  NavMenuColor,
  SelectedNavigationMenu,
} from "../../constants/ColorConstants";
import { useDispatch } from "react-redux";
import { removeAuthData } from "../../data/action";
import companyIcon from "../../assets/logo/icon.png";
import companyLogo from "../../assets/logo/logo.png";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { updateOrgSids } from "../../data/organisation/action";
import {
  eCommerceRoute,
  organisationNewRoute,
  subscriptionRoute,
  technologiesRoute,
  universityRoute,
  userListRoute,
} from "../../constants/routesLinks";
import { updateSids } from "../../data/ecommerceStore/action";
import { updateTechSids } from "../../data/technologyStore/action";
import FeedbackModal from "../feedbackmodal/FeedbackModal";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import { toolTipValue } from "./Util";

const NavigationMenu = () => {
  const classes = navigationMenuStyles();
  const openDrawer = useBooleanState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleMenuClick = (url: string) => {
    if (url === organisationNewRoute) {
      dispatch(updateOrgSids([]));
      history.push(url);
      return;
    }
    if (url === subscriptionRoute) {
      history.push(url);
      return;
    }
    if (url === universityRoute) {
      history.push(url);
      return;
    }
    if (url === technologiesRoute) {
      history.push(url);
      dispatch(updateTechSids(""));
      return;
    }
    if (url === userListRoute) {
      history.push(url);
      return;
    }
    if (url === eCommerceRoute) {
      history.push(url);
      dispatch(updateSids([]));
      return;
    }
    if (url === technologiesRoute) {
      history.push(url);
      return;
    }
    history.push("/logout");
    dispatch(removeAuthData());
  };

  return (
    <div>
      <Drawer
        PaperProps={{
          style: {
            background: NavMenuColor,
          },
        }}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: openDrawer.value,
          [classes.drawerClose]: !openDrawer.value,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: openDrawer.value,
            [classes.drawerClose]: !openDrawer.value,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {openDrawer.value ? (
            <img src={companyLogo} height={30} />
          ) : (
            <img src={companyIcon} height={40} />
          )}
        </div>
        <Divider />
        <List style={{ flex: 1 }}>
          {navigationLinks
            .filter((item) => item.toShow !== "0")
            .map((menu) => (
              <HtmlToolTipWrapper
                key={menu.id}
                isselected={location.pathname.includes(menu.url) ? 1 : 0}
                title={!openDrawer.value ? toolTipValue(menu.menuTitle) : ""}
                placement={"right-start"}
              >
                <ListItem
                  style={{
                    background: location.pathname.includes(menu.url)
                      ? SelectedNavigationMenu
                      : undefined,
                  }}
                  button
                  onClick={() => handleMenuClick(menu.url)}
                >
                  <ListItemIcon style={{ color: "white", minWidth: 40 }}>
                    {menu.icon}
                  </ListItemIcon>
                  <ListItemText style={{ color: "white" }}>
                    {menu.menuTitle}
                    {menu.isInBeta && (
                      <span style={{ fontSize: 10, marginLeft: 3 }}>
                        {" "}
                        (Beta)
                      </span>
                    )}
                  </ListItemText>
                </ListItem>
              </HtmlToolTipWrapper>
            ))}
          <FeedbackModal isDrawerOpen={openDrawer.value} />
          <Divider />
          <HtmlToolTipWrapper
            isselected={0}
            title={!openDrawer.value ? toolTipValue("Log out") : ""}
            placement={"right-start"}
          >
            <ListItem button onClick={() => handleMenuClick("")}>
              <ListItemIcon style={{ color: "white", minWidth: 40 }}>
                <PowerSettingsNewOutlinedIcon />
              </ListItemIcon>
              <ListItemText style={{ color: "white" }}>Logout</ListItemText>
            </ListItem>
          </HtmlToolTipWrapper>
        </List>
        {openDrawer.value ? (
          <ListItemIcon
            onClick={() => openDrawer.setFalse()}
            className={classes.drawerCloseIcon}
          >
            <ArrowBackIosIcon />
          </ListItemIcon>
        ) : (
          <ListItemIcon
            onClick={() => openDrawer.setTrue()}
            className={classes.drawerCloseIcon}
          >
            <ArrowForwardIosIcon />
          </ListItemIcon>
        )}
      </Drawer>
    </div>
  );
};

export default NavigationMenu;

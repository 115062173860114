import { Redirect, Switch } from "react-router";
import { ORGANISATION_NEW_FLAG } from "../config/app";
import {
  eCommerceRoute,
  logoutRoute,
  organisationNewRoute,
  profileRoute,
  resetPasswordRoute,
  subscriptionRoute,
  techDetailRoute,
  technologiesRoute,
  universityRoute,
  userListRoute,
} from "../constants/routesLinks";
import PrivateRoute from "../route/PrivateRoute";
import DownloadFileView from "./downloadfile/DownloadFileView";
import { NavigationBarStyle } from "./drawer/SideNavbarStyle";
import EcommerceView from "./ecommerce/EcommerceView";
import Logout from "./logout/Logout";
import OrganisationView from "./organisation/OrganisationView";
import Profile from "./profile/Profile";
import ResetPassword from "./resetpassword/ResetPassword";
import SubscriptionView from "./subscription/SubscriptionView";
import TechnologyItemDetailPanel from "./technology/TechnologyDetailPanel/TechnologyItemDetailPanel";
import TechnologyView from "./technology/TechnologyView";
import University from "./university/University";
import UserFacetList from "./userfacetlist/UserFacetList";

const NavigationRoutes = () => {
  const classes = NavigationBarStyle();
  return (
    <main className={classes.content}>
      <Switch>
        <PrivateRoute
          exact
          path={"/"}
          component={() => (
            <Redirect
              to={
                ORGANISATION_NEW_FLAG === "1"
                  ? organisationNewRoute
                  : eCommerceRoute
              }
            />
          )}
        />
        <PrivateRoute exact path={eCommerceRoute} component={EcommerceView} />
        <PrivateRoute exact path={profileRoute} component={Profile} />
        <PrivateRoute
          exact
          path={subscriptionRoute}
          component={SubscriptionView}
        />
        <PrivateRoute
          exact
          path={technologiesRoute}
          component={TechnologyView}
        />
        <PrivateRoute
          exact
          path={organisationNewRoute}
          component={OrganisationView}
        />
        <PrivateRoute
          exact
          path={techDetailRoute}
          component={TechnologyItemDetailPanel}
        />
        <PrivateRoute exact path={userListRoute} component={UserFacetList} />
        <PrivateRoute
          exact
          path={resetPasswordRoute}
          component={ResetPassword}
        />
        <PrivateRoute exact path={universityRoute} component={University} />
        <PrivateRoute
          exact
          path={"/export/download/:id"}
          component={DownloadFileView}
        />
        <PrivateRoute exact path={logoutRoute} component={Logout} />
      </Switch>
    </main>
  );
};

export default NavigationRoutes;
